export default {
  password: {
    pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,}$/,
    msg: '密码必须满足大写字母、小写字母、特殊符号、数字等其中最少三种类型且满足8位数以上'
  },
  phone: {
    pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
    msg: '手机号码格式不正确'
  }
}
